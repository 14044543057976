<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/issuelog`"
                title="Issue Log"
                :columns="columns"
                routerpath="/setup/addissuelog"
                :formOptions="formOptions"
                :newPage="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },
        {
          label: "Task Type",
          field: "tasktype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Task Type",
          },
        },
        {
          label: "Cardinality",
          field: "cardinality",
          filterOptions: {
            enabled: true,
            placeholder: "Search Cardinality",
          },
        },
        {
          label: "Module",
          field: "moduleName",
          filterOptions: {
            enabled: true,
            placeholder: "Search Module Name",
          },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search Description",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "dropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "dropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id ? "Edit Softwares" : "Add Softwares"
        }`,
        submitRouterPath: "/setup/softwares",
        usersDropdown: true,
        getEditValue: `${baseApi}/getsoftwaresById`,
        method: "post",
        action: "add",
        gridForm: true,
        url: `${baseApi}/softwares`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Sofware Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Sofware Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Installed",
            name: "installed",
            value: true,
            checked: true,
            type: "switch",
            class: "col-md-6",
          },
        ],
      },
    };
  },
  beforeMount() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
